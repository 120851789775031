<template>
    <AppPaginatedGrid
        ref="grid"
        :service="service"
        newPath="/atividades-especificas/new"
        editPath="/atividades-especificas/"
        :expander="false"
        subtitle="Atividade Especifica"
        tooltip="Cadastro de atividade especifica"
        :tipo="tipoFiltroEnum.SISTEMA"
        :modulo="moduloFiltroEnum.ATIVIDADES_ESPECIFICAS"
        permission="gestaosesmt_cadastros_atividades_especificas"
        descricao="FILTRO PADRÃO ATIVIDADE ESPECIFICADA"
        nomeTelaDoManual="atividades-especificas-list"
        origemHistoricoAcao="/atividades-especificas"
    >
        <template #columns>
            <Column field="id" header="Cod." :sortable="true" sortField="id"></Column>
            <Column field="nome" header="Nome" :sortable="true" sortField="nome"></Column>
            <Column field="createdAt" header="Criado" :sortable="true" sortField="createdAt">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.createdAt) }}
                </template>
            </Column>
            <Column field="updatedAt" header="Alterado" :sortable="true" sortField="updatedAt">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.updatedAt) }}
                </template>
            </Column>
        </template>
    </AppPaginatedGrid>
</template>

<script>
import BaseService from '@/services/BaseService';
import { getCurrentCompany, getCurrentCustomers } from '@/services/store';
import ModuloFiltroEnum from '../../enums/ModuloFiltroEnum';
import TipoFiltroEnum from '../../enums/TipoFiltroEnum';
export default {
    data() {
        return {
            tenantCustomers: [],
            service: null,
            page: 1,
            total: 0,
            loading: false,
            totalPage: 0,
            perPage: 10
        };
    },
    mounted() {
        this.tenantCustomers = getCurrentCustomers();
    },
    created() {
        this.loading = true;
        this.service = new BaseService('atividades-especificas');
        this.loading = false;
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        },
        moduloFiltroEnum() {
            return ModuloFiltroEnum;
        },
        tipoFiltroEnum() {
            return TipoFiltroEnum;
        }
    },
    watch: {
        tenant() {
            this.load();
        },
        currentCustomer() {
            this.tenantCustomers = getCurrentCustomers();
            this.load();
        }
    },
    methods: {
        async load() {
            this.$refs.grid.load();
        }
    }
};
</script>
